import DOMPurify from "isomorphic-dompurify";
import Image from "next/future/image";
import Link from "next/link";

import { getSection } from "../utility";

const CommunityComponent = (props: any) => {
  const section = getSection(props);

  const title = section && section[10] && section[10].Title;
  const cardTitle = section && section[11] && section[11].Title;
  const cardDesc = section && section[11] && section[11].Description;
  const link = section && section[11] && section[11].Internal_Link;
  const cardImg =
    (section &&
      section.length &&
      section[11]?.Featured_Image?.data?.attributes?.url) ||
    "";
    const cardImgAlt =
    (section &&
      section.length &&
      section[11]?.Featured_Image?.data?.attributes?.alternativeText) ||
    '';
  return (
    <>
      <div className="container-xl custom-container px-0">
        <section className="community">
          <h2 className="section-heading d-none d-xl-block d-lg-block d-md-block">
            {title}
          </h2>
          <h2 className="section-heading d-block d-xl-none d-lg-none d-md-none">
            {cardTitle}
          </h2>
          <div className="container-fluid">
            <div className="com-info">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0 d-none d-xl-block d-lg-block d-md-block">
                  <div className="about-com">
                    <div className="about-com-article">
                      <h4 className="heading">{cardTitle}</h4>
                      <div
                        className="para"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(cardDesc),
                        }}
                      ></div>
                      <Link href={link?.Slug_or_URL || ""}>
                        <button type="button" className="btn se-primary-btn">
                          {link?.Text}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 p-0">
                  <div className="image-column">
                    <Image
                      sizes="(max-width: 375px) 300px, (max-width: 576px) 540px, (max-width: 768px) 380px, (max-width: 992px) 500px, (max-width: 1280px) 640px"
                      src={cardImg}
                      alt={cardImgAlt || "kids-img"}
                      width="768"
                      height="450"
                      className="w-100 com-img"
                    />
                  </div>

                  <div className="about-section-sm d-block d-xl-none d-lg-none d-md-none">
                    <div className="container">
                      <div
                        className="md-para"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(cardDesc),
                        }}
                      ></div>
                      <Link href={link?.Slug_or_URL}>
                        <button type="button" className="btn se-primary-btn">
                          {link?.Text}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default CommunityComponent;
